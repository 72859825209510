import React from 'react';
import './App.css';

function App() {

  // const apiRoot = 'https://buyerapi.shopgoodwill.com/api';
  // const login = '/SignIn/Login';

  // const shopGoodwill = async () => {
  //   return fetch(apiRoot + login, {
  //     method: 'GET',
  //     headers: {
  //       browser: 'firefox',
  //       remember: 'False',
  //       clientIpAddress: '0.0.0.4',
  //       appVersion: '00099a1be3bb023ff17d',
  //       username: 'oKKwBtp%2FGwERooBKelx10g%3D%3D',
  //       password: 'oJW%2BQsg3B7Xg%2FYwdTwXgEjlyWUgpbgRO0dxy2Nun%2Fq8%3D',
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => console.log(res));
  // };

  // shopGoodwill();

  return (
    <>
      
      <div className="cutie-boy-container">
      <h1 className="grays">Cutie Boy of the Year</h1>
      <h2>2023</h2>
        <img
          alt="Cutie Boy of the Year"
          className="cutie-boy"
          src="cutieBoyOfTheYear2023.png"
        />
      </div>
    </>
  );
}

export default App;
